import React from 'react'
import{FaYoutube,FaTwitch,FaSteam,FaGooglePlay} from 'react-icons/fa'
import{FooterCointainer,FooterWrap,FooterLinksContainer,FooterLinksWrapper,SocialMedia,SocialMediaWrap,SocialLogo,WebsiteRights,SocialIcons,SocialIconLink} from './footerComponents'
const Footer = ({id}) => {
    return (
        <>
        <FooterCointainer id='contactus'>
            <FooterWrap>
                <FooterLinksContainer>
                    <FooterLinksWrapper>
                        {/* <FooterLinkItems>
                            <FooterLinkTitle>

                            </FooterLinkTitle>
                        </FooterLinkItems> */}
                    </FooterLinksWrapper>
                </FooterLinksContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/'> rxarinta-games</SocialLogo>
                        <WebsiteRights> rxarinta-games {new Date().getFullYear()} © All rights reserved</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="https://www.youtube.com/channel/UCRrjPo-KLxawW7TCOXu67jg/" target="_blank" aria-label="Youtube"><FaYoutube/></SocialIconLink>
                            <SocialIconLink href="https://www.twitch.tv/rxarintagames" target="_blank" aria-label="Twitch"><FaTwitch/></SocialIconLink>
                            <SocialIconLink href="https://store.steampowered.com/search/?developer=Rania%20Rizki%20Arinta&snr=1_5_9__2000" target="_blank" aria-label="Steam"><FaSteam/></SocialIconLink>
                            <SocialIconLink href="https://play.google.com/store/apps/dev?id=6580238222884344799" target="_blank" aria-label="GooglePlay"><FaGooglePlay/></SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterCointainer>
            
        </>
    )
}

export default Footer
