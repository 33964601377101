import React,{useState} from 'react'
// import Video from '../../videos/video.mp4'
import{Button} from '../ButtonComponents'
import Videomov from '../../videos/game-trailer.mp4'

import{HeroContainer,HeroBg,VideoBg,HeroContent,HeroH1,HeroP,HeroBtnWrapper,ArrowForward,ArrowRight} from './HeroComponents'

const Herosection = () => {
    const[hover,setHover]=useState(false)
    const onHover=() =>{
        setHover(!hover)
    }


    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Videomov} type='video/mp4' />
            </HeroBg>
            <HeroContent>
                <HeroH1>Cyber Grid: Tower Defense</HeroH1>
                <HeroP>
                    Download Now On Steam 
                </HeroP>
                <HeroBtnWrapper>
                    <Button to="about" onMouseEnter={onHover} onMouseLeave={onHover}>
                        Download Now{ hover ? <ArrowForward/> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default Herosection
