import React from 'react'
import{SidebarContainer,Icon,CloseIcon,SidebarWrapper,SidebarMenu,SidebarLink,SideBtnWrap,SidebarRoute} from './sidebarComponents'

const Sidebar = ({isOpen,toogle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toogle}>
            <Icon onClick={toogle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about"onClick={toogle} >About</SidebarLink>
                    <SidebarLink to="product"onClick={toogle} >Products</SidebarLink>
                    <SidebarLink to="upcoming-releases"onClick={toogle} >Upcoming Releases</SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to='contactus'onClick={toogle}>Contact Us</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar
