import React,{useEffect, useState} from 'react'
import{FaBars} from 'react-icons/fa'
import{Nav,NavLogo,NavContainer,MobileIcon,NavMenu,NavItem,NavLinks,NavBtn,NavBtnLink} from './NavbarComponens'
import{animateScroll as scroll} from 'react-scroll';
const Navbar = ({toogle}) => {
    const[scrollNav, setScrollNav]=useState(false)

    const changeNav=()=>{
        if(window.scrollY>=80){
            setScrollNav(true)
        }
        else{
            setScrollNav(false)
        }
    }

    useEffect(()=>{
        window.addEventListener('scroll',changeNav)
    },[])

    const toogleHome =() =>{
        scroll.scrollToTop();
    }



    return (
        <>
        <Nav scrollNav={scrollNav}>
            <NavContainer>
                <NavLogo to='/' onClick={toogleHome}>rxarinta-games</NavLogo>
                <MobileIcon onClick={toogle}>
                <FaBars />
                </MobileIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to="about" smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass="active"> About</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="product" smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass="active"> Product</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="upcoming-releases"smooth={true} duration={500} spy={true} exact='true' offset={-80} activeClass="active"> Upcoming Releases</NavLinks>
                    </NavItem>
                    <NavBtn>
                        <NavBtnLink to="contactus"smooth={true} duration={500} spy={true} exact='true' offset={-80} > Contact Us</NavBtnLink>
                    </NavBtn>
                </NavMenu>
            </NavContainer>
        </Nav>
        </>
    )
}

export default Navbar
