import React from 'react'
import { Button } from '../ButtonComponents'
import { Colum2, Img, ImgWrap,InfoContainer,InfoWrapper,Colum1,TextWrapper,TopLine,Heading,Subtitle,BtnWrap, InfoRow } from './infosectionComponents'
const InfoSection = ({lightBg,id,topLine,lightText,darkText,headLine,description,buttonLabel,img,path,primary,dark,imgStart,scroll}) => {
    return (
        
        <InfoContainer lightBg={lightBg} id={id} >
            <InfoWrapper>
                <InfoRow imgStart={imgStart}>
                <Colum1>
                <TextWrapper>
                    <TopLine>{topLine}</TopLine>
                    <Heading lightText={lightText}>{headLine}</Heading>
                    <Subtitle darkText={darkText}>{description}</Subtitle>
                    <BtnWrap>
                        <Button onClick={(e) => {
                            e.preventDefault();
                            window.open(path, "_blank") 
                            // window.location.href=path; 
                            }} to={scroll}smooth={true} duration={500} spy={true} exact="true"
                        offset={-80}>{buttonLabel} 
                         </Button>

                         {/* <iframe src="https://store.steampowered.com/widget/1563710/" frameborder="0" width="646" height="190"></iframe> */}




                    </BtnWrap>

                </TextWrapper>
                </Colum1>
                <Colum2>
                <ImgWrap>
                <Img  src={img} />
                </ImgWrap>
                </Colum2>
                </InfoRow>
            </InfoWrapper>
        </InfoContainer>
    )
}

export default InfoSection
