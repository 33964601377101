import React,{useState} from 'react'
import Footer from '../components/footer'
import Herosection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import { homeObjFour, homeObjOne, homeObjThree, homeObjTwo } from '../components/InfoSection/data'
import InfoHome from '../components/InfoSection/info'


import Navbar from '../components/Navbar'
import Sidebar from '../components/sidebar'

const Home = () => {
    const[isOpen,setIsOpen]=useState(false)

    const toogle =()=>{
        setIsOpen(!isOpen)
    }
    return (
        <>
        <Sidebar isOpen={isOpen} toogle={toogle}/>
        <Navbar toogle={toogle}/>
        <Herosection/>
        <InfoHome{...homeObjOne}/>
        <InfoSection{...homeObjTwo}/>
        <InfoSection{...homeObjThree}/>
        <InfoSection{...homeObjFour}/>
        <Footer/>
            
        </>
    )
}

export default Home
