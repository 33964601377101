import styled from'styled-components'
import {Link} from 'react-router-dom'

{/* <FooterCointainer>
<FooterWrap>
    <FooterLinksContainer>
        <FooterLinksWrapper> */}


export const FooterCointainer =styled.footer`
background-color:#101522;
`
export const FooterWrap =styled.div`
padding:48px 24px;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
max-width:1100px;
margin:0 auto;
`
export const FooterLinksContainer =styled.div`
display:flex;
justify-content: center;
@media screen and (max-width: 820px){
    padding-top:32px;

}
`
export const FooterLinksWrapper =styled.div`
display:flex;
@media screen and (max-width: 820px){
    flex-direction:column;
}
`
export const SocialMedia = styled.section`
max-width:1000px;
width:100%;
`

export const SocialMediaWrap =styled.div`
display:flex;
justify-content:space-between;
align-items:center;
max-width:1100px;
margin:40px auto 0 auto;
@media screen and (max-width: 820px){
    flex-direction:column;
}
`

export const SocialLogo =styled(Link)`
color:#fff;
justify-self:start;
cursor:pointer;
text-decoration:none;
font-size:1.5rem;
display:flex;
align-items:center;
margin-bottom:16px;
font-weight:bold;
`

export const WebsiteRights =styled.small`
color:#fff;
margin-bottom:16px;
`
export const SocialIcons =styled.div`
display:flex;
justify-content:space-between;
align-items:center;
width:240px;
`

export const SocialIconLink = styled.a`
color:#fff;
font-size:24px;
`