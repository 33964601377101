import info_img2 from '../../images/about.svg'
import info_img4 from '../../images/Feature-aset.png'
import info_img5 from '../../images/5.png'
import info_img6 from '../../images/6.png'


export const homeObjOne={
    id:'about',
    lightBg:true,
    lightText:true,
    lightTextDesc: true,
    topLine: 'Download Games',
    headLine:'Download Games and Access it through your mobile device or PC',
    description:'get access to our game app to your device with steam and google play',
    buttonLabel:'Get started',
    imgStart:false,
    img: info_img2,
    dark:true,
    primary:true,
    darkText:true,
    scroll:'product',
   
}

export const homeObjTwo={
    id:'product',
    lightBg:false,
    lightText:false,
    lightTextDesc: true,
    topLine: 'Product',
    headLine:'Cyber Grid: Tower Defense',
    description:'CyberGrid: Tower defense is a Tower defense game containing HQ, Tower, and 3 building that generates resources. In order to build a building, you must have enough resources. Each building can generate different resources. You must protect the HQ from the Enemy. ',
    buttonLabel:'Download now on steam',
    imgStart:true,
    img: info_img5,
    dark:false,
    primary:true,
    darkText:false,
    path:'https://store.steampowered.com/app/1563710/CyberGrid_Tower_defense/',
}

export const homeObjThree={
    id:'product',
    lightBg:true,
    lightText:true,
    lightTextDesc: true,
    topLine: 'Product',
    headLine:'RK Space Adventure',
    description:'A 2D arcade Game with 2 Map Location New Colony and Outer Space. Control the Rocket by Tapping the screen Avoid the Base Tower and Collect Coins.',
    buttonLabel:'Download now on Google Play',
    imgStart:false,
    img: info_img4,
    dark:true,
    primary:true,
    darkText:true,
    path:'https://play.google.com/store/apps/details?id=com.RxarintaGames.RKSpaceAdventure',
}

export const homeObjFour={



    id:'upcoming-releases',
    lightBg:false,
    lightText:false,
    lightTextDesc: true,
    topLine: 'upcoming releases',
    headLine:'Vending Shaker',
    description:'A 2D arcade Game. Shake your phone and collect all collectable from the diffrent type of vending machine. Coming Soon this May 2021.',
    buttonLabel:'Coming Soon on Google Play',
    imgStart:true,
    img: info_img6,
    dark:false,
    primary:true,
    darkText:false,
    path:'https://play.google.com/store/apps/dev?id=6580238222884344799',
}